// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeRightText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeMiddleText,
    placeSliderSwitch,
    placeTest,
    placeShuffle,
    placeLogo
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 6, 6, -6],keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  makeResponsive(brd1);
  placeLogo(brd1);
  brd1.options.layer['image'] =14;
  brd1.options.layer['chart'] =14;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Integers on a Number Line', 'Find the Location of the Monkey Mo.');
  //brd1.create('axis', [[-1, 0], [1, 0]], {strokeWidth: 2, strokeColor: 'red'});

  var monkey = brd1.create('image', ['/assets/monkeyMO.svg', [-0.5,0], [1,1]], {fixed:true});
  var seat = brd1.create('point', [()=>monkey.X()+0.5,0],{face:'triangledown', size:6, name:'', fillColor:'yellow',strokeColor:'black'});
  //var test=placeImage(brd1, '/assets/test.svg', 2., -2.5, 1, 0);
  var test = placeTest(brd1);
  test.on('down', function(){jump()});
  test.setLabel('Tap to Test Your Answer')
  test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  test.on('over', function () {this.label.setAttribute({visible:true});});
  test.on('out', function () {this.label.setAttribute({visible:false});});
  //////////////////////////////////////////////////////////////////////////////////////
  var k =0;
  //var reload=placeImage(brd1, '/assets/shuffle.svg', 4., -2.5, 1, 0);
  var reload = placeShuffle(brd1);
  reload.setLabel('Tap to Move the Monkey');
  reload.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  reload.on('down', function(){k=Math.min(k+1, 6); hop();});
  ///////////////////////////////////////////////////////////////////////////////
  var anno =placeMiddleText(brd1, 0, 3, '');
  placeRightText(brd1, -0.25, -2., 'Monkey is at X =');
  var hopD = [2, -3, 4, -5, 6, -4, 2];
  function monkeyHop(){
      return monkey.moveTo([monkey.X()+0.5*hopD[k], 0], 10)
  }
  function monkeyDown(){
      return monkey.moveTo([monkey.X(), 0], 10)
  }
  function jump()
  {
      if(input.Value() == monkey.X()+0.5)
      {
          monkey.moveTo([monkey.X(), 1], 10);
          setTimeout(function(){monkeyDown(); }, 200);
          anno.setText('Yay! That is right.');
          anno.setAttribute({color:'green'});
          return ;
      }
      else
      {
          anno.setText('Nope! Try again.');
          anno.setAttribute({color:'red'});
      }
  }
  function hop()
  {
    monkey.moveTo([monkey.X()+0.5*hopD[k], 1], 100);
    setTimeout(function(){monkeyHop(); }, 300);
    return ;
  }

  //brd1.create('text', [-5, 1, function(){return "INPUT VALUE =  "+ input.Value()}], { CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});

  //var input = brd1.create('input', [-4, -2, '0', 'Monkey is at ='], {CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  var input = placeInput(brd1, 0, -2, " ");
  //var input = brd1.create('text', [1, 3, '<input type="text" name="txt" value=" " onchange="jump()">']);

},
}
export default Boxes;
